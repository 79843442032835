.MastheadCarousel-container > figure {
  animation: imageAnimation 30s linear infinite 0s;
  background-size: cover;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0px;
  width: 100%;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .MastheadCarousel-container > figure:nth-child(1) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2000/v1591469506/presenters/jason-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(2) {
    animation-delay: 6s;
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2000/v1591469506/presenters/hien-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(3) {
    animation-delay: 12s;
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2000/v1591421273/presenters/howard-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(4) {
    animation-delay: 18s;
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2000/v1591469505/presenters/adam-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(5) {
    animation-delay: 24s;
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2000/v1591482904/presenters/russ-01-fade.jpg");
  }
}

@media only screen and (min-width: 991px) {
  .MastheadCarousel-container > figure:nth-child(1) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2200/v1591469506/presenters/jason-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(2) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2200/v1591469506/presenters/hien-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(3) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2200/v1591421273/presenters/howard-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(4) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2200/v1591469505/presenters/adam-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(5) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2200/v1591482904/presenters/russ-01-fade.jpg");
  }
}

@media only screen and (min-width: 1200px) {
  .MastheadCarousel-container > figure:nth-child(1) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2500/v1591469506/presenters/jason-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(2) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2500/v1591469506/presenters/hien-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(3) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2500/v1591421273/presenters/howard-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(4) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2500/v1591469505/presenters/adam-01-fade.jpg");
  }
  .MastheadCarousel-container > figure:nth-child(5) {
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/b_rgb:000000,c_pad,g_east,q_auto:best,h_1000,w_2500/v1591482904/presenters/russ-01-fade.jpg");
  }
}

@keyframes imageAnimation {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }
  8% {
    animation-timing-function: ease-out;
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
