.Polygon-polygon {
  visibility: hidden;
}

@media only screen and (min-width: 768px) {
  .Polygon-polygon {
    visibility: visible;
    position: absolute;
    width: 100%;
    height: 100%;
    fill: #333333;
    opacity: 0.8;
    z-index: 1;
  }
}
