.ui.vertical.segment.Home-Masthead-background {
  padding: 4em 0em;
  min-height: 590px;
  display: flex;
  align-items: center;
  background-size: cover;
}

.ui.vertical.segment .Home-Masthead-header {
  font-size: 3em;
  line-height: 0.95em;
  text-shadow: 2px 2px 100px rgba(0, 0, 0, 1);
}

.ui.vertical.segment .Home-Masthead-container {
  margin-bottom: 0em;
  z-index: 100;
}

.ui.aligned.grid .column > .segment:not(.compact):not(.attached).Home-Model-segment {
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 3;
}

.ui.vertical.segment .Home-Segment-Padded {
  padding-top: 4em;
  padding-bottom: 4em;
}

.ui.vertical.segment .Home-Segment-Grey {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 767px) {
  .ui.vertical.segment.Home-Masthead-background {
    background-size: cover;
    background-image: url("https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591656978/web/tiled-presenters.jpg");
  }
}

@media only screen and (min-width: 768px) {
  .ui.vertical.segment.Home-Masthead-background {
    min-height: 650px;
  }

  .ui.vertical.segment .Home-Masthead-header {
    font-size: 5em;
  }

  .ui.aligned.grid .column > .segment:not(.compact):not(.attached).Home-Model-segment {
    margin-left: -10%;
    width: 110%;
  }
}
