.ui.inverted.vertical.segment.Shows-Masthead {
  padding: 0;
  position: relative;
  background-size: cover;
  background-image: url("https://res.cloudinary.com/green-ocean/image/upload/q_auto/f_auto/v1523818376/web/mini-masthead-background.jpg");
}

.ui.inverted.vertical.segment .Shows-Masthead-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ui.inverted.vertical.segment .Shows-Masthead-header {
  position: absolute;
  bottom: 2em;
  padding-left: 1em;
}

.ui.vertical.segment .ui.grid > .column:not(.row) {
  padding-bottom: 7rem;
}

.ui.vertical.segment .Shows-Grid h4.ui.header {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 1em;
}

.ui.vertical.segment .Shows-Segment-Padded {
  padding-top: 4em;
  padding-bottom: 4em;
}

.ui.vertical.segment .Shows-Segment-Grey {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 767px) {
  .ui.vertical.segment .Shows-Segment-Padded {
    margin-top: 0;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .Shows-Masthead-header-mobile {
    visibility: visible;
  }

  .Shows-Masthead-header-tablet {
    visibility: hidden;
  }
}

@media only screen and (min-width: 768px) {
  .Shows-Masthead-header-mobile {
    visibility: hidden;
    display: none;
  }

  .Shows-Masthead-header-tablet {
    visibility: visible;
  }
}
