.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content {
  flex: 1 0 auto;
}

.App-logo-container {
  position: absolute;
  top: 5px;
  width: 300px;
  z-index: 10;
  overflow: hidden;
}

.App-logo {
  width: 300px;
}

.App-body {
  min-height: 750px;
}

@media only screen and (max-width: 767px) {
  /* this stops the logo overlapping the buttons */
  .App-logo-container {
    width: 100px;
  }
}
